import React from "react";

global.platformURI = 'https://api.deviare.africa/'
global.cookieURI = 'http://identity.deviare.africa:80/cookie.php?identity=';
global.keycloak = 'http://identity.deviare.africa:80/cookie.php?identity=';
global.eLearningURI = 'https://accounts.simplilearn.com/api/v1/get-course-catalogue';
global.keycloakURL = 'https://identity.deviare.africa/auth';
global.keycloakRealm = 'Deviare';
global.keycloakClientId = 'reactjsclient';
global.keycloakRelay = 'https://identity.deviare.africa/auth/realms/Deviare/protocol/saml/clients/{realm_client}?RelayState=';
global.keycloakDigitalSkills = 'https://identity.platform.digitalskillsonline.net/auth';